<template>
  <div class="flex overflow-hidden">
    <Sidebar/>

    <div class="flex flex-col p-3 w-4/5 h-screen overflow-y-auto overflow-x-hidden">
      <div class="flex justify-between items-center text-4xl font-bold mt-6 ml-4 mb-14">
        {{ t('services_list.services') }}
      </div>


      <div
        class="flex flex-col m-4 flex-grow"
        :class="loaded? '' : 'opacity-40 pointer-events-none'">
        <div class="my-2 overflow-x-auto no-scrollbar sm:-mx-6 lg:-mx-8">
          <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr class=" h-12 py-2  text-gray-600 text-xs rounded-lg">
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{ t('services_list.service_id') }}
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{ t('services_list.service_name') }}
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{ t('services_list.ticket_type') }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <template v-for="service in services">
                  <tr class="h-16 py-2 text-sm hover:bg-gray-100">
                    <td class="px-6 py-4 whitespace-nowrap text-xs">
                      {{ service.serviceId }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-xs">
                      {{ service.name }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-xs">
                      <Dropdown
                        class="styled-small"
                        v-model="service.jiraTicketType"
                        v-on:change="assignIssueType(service)"
                        optionValue="value"
                        optionLabel="name"
                        :options="jiraIssueTypes">
                      </Dropdown>
                    </td>
                  </tr>
                </template>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import Sidebar from '@/components/common/Sidebar.vue';
import httpClient from '@/services/httpClient';

export default {
  name: 'Services',

  components: {
    Sidebar,
  },

  setup() {
    const {t} = useI18n();
    const services = ref([]);
    const loaded = ref(false);
    const jiraIssueTypes = ref([]);

    onMounted(async () => {
      loaded.value = false;

      const response = await httpClient.get(
        '/api/v1/dgr/services', {});

      const jiraResponse = await httpClient.get(
        '/api/v1/dgr/jira_get_issue_types', {});

      services.value = response.data;

      jiraIssueTypes.value = jiraResponse.data.map(f => { return { name: f.name, value: f.id } });

      loaded.value = true;
    })

    const assignIssueType = async (service) => {
      loaded.value = false;
      console.warn(service);

      const jiraResponse = await httpClient.post(
        '/api/v1/dgr/service_update', {...service});

      loaded.value = true;
    }

    return {
      t,
      loaded,
      services,
      jiraIssueTypes,
      assignIssueType,
    }
  }
}
</script>


<style lang="css">
input[type='checkbox'] {
  filter: grayscale(1) !important;
}

.p-dropdown {
  min-width: 220px;
}
.p-dropdown-label.p-inputtext {
  font-size: 12px;
  padding: 6px;
}
.p-dropdown-item {
  font-size: 12px;
}
</style>
